<template>
  <div>
    <card>
      <div slot="header">
      <div class='row'>
      <div class='col-md-10'>
        <h4 class="card-title">Best Australia Betting Companies Lists</h4>
      </div>
      <div class='col-md-2'>
            <router-link to="/sidebar/bestAustralia/add" replace>
              <n-button type="primary" round>
                <i slot="label" class="now-ui-icons ui-1_simple-add"></i>
                Add
              </n-button>
            </router-link>

      </div>
      </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData">
            <el-table-column min-width="50" type="index"></el-table-column>
            <el-table-column min-width="50">
                <div slot-scope="{row}" class="img-container">
                <img :src="row.logo" alt="logo">
                </div>
            </el-table-column>            
            <el-table-column min-width="150" prop="name"
                             label="name">
            </el-table-column>
             <el-table-column min-width="150" prop="siteLink"
                             label="Site Link">
            </el-table-column>
            <!-- <el-table-column min-width="150" prop="Date"
                             label="Date">
            </el-table-column> -->
            <el-table-column min-width="150"
                             header-align="right"
                             label="Actions">
              <div slot-scope="{row}" class="text-right table-actions">
                <router-link v-bind:to="{name: 'Best Australia Betting Sites Edit', params: { item_id: row.id }}">
                  <el-tooltip content="Settings"
                              :open-delay="300"
                              placement="top">
                    <n-button type="warning" size="sm" icon>
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip>
                </router-link>

              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import db from '@/components/firebaseInit'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
      return {
          tableData: []
      }
  },
  created () {
    db.collection('bestAustraliaBettingSites').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = {
          'logo': doc.data().logo,
          'id': doc.id,
          'name': doc.data().name,
          'siteLink': doc.data().siteLink
        }
        this.tableData.push(data)
      })
    })
  }
};
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
