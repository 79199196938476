<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">Change Password</h4>
        </template>
        <div>
            <!--  onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal" -->
          <form method="#" action="#" @submit.prevent="editPassword">
            <div class="row">
              <label class="col-sm-2 col-form-label">Old Password</label>
              <div class="col-sm-4">
                <fg-input v-model="oldp" type="password" placeholder="Enter the Old Password">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">New Password</label>
              <div class="col-sm-4">
                <fg-input v-model="newp" type="password" placeholder="Enter the Password">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Re-enter New Password</label>
              <div class="col-sm-4">
                <fg-input v-model="renewp" type="password" placeholder="Re-enter new password">
                </fg-input>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Edit
              </n-button>
              </div>
            </div>
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';

export default {
  components: {
    Checkbox,
    Radio,
    [Option.name]: Option,
    [Select.name]: Select
  },
  data() {

    return {
      oldp: null,
      newp: null,
      renewp: null
    };
  },
      methods: {

            // reauthenticate () {
            //     var user = firebase.auth().currentUser;
            //     var cred = firebase.auth.EmailAuthProvider.credential(
            //         user.email, this.oldp);
            //     return user.reauthenticateWithCredential(cred);
            // },
            editPassword () {

             if(this.newp == this.renewp){

                var user = firebase.auth().currentUser;
                var cred = firebase.auth.EmailAuthProvider.credential(
                        user.email, this.oldp);

                user.reauthenticateAndRetrieveDataWithCredential(cred).then(() => {

                    var user = firebase.auth().currentUser;
                    user.updatePassword(this.newp).then(() => {
                    
                    console.log("Password updated!");
                    alert("Password updated!");
                    this.$router.push('/homepage')
                    
                    }).catch((error) => {          
                        console.log(error);
                        alert(error);
                        this.$router.push('/settings/changePassword')
                    
                    });

                }).catch((error) => {
                    console.log(error); 
                    alert(error);
                    this.$router.push('/settings/changePassword')
                        
                    });

             }else{
                    alert("The new passwords doesn't match.");
                    this.$router.push('/settings/changePassword')
             }   

        }


      }
};
</script>
<style>
</style>
