var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('user-menu'),_c('sidebar-item',{attrs:{"link":{ name: 'Homepage', icon: 'fas fa-home' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Banners', path: '/homepage/banners' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Latest Prediction', path: '/homepage/latestPredictions' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Australia Review', path: '/homepage/australiaReview' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'USA Review', path: '/homepage/usaReview' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Sidebar', icon: 'fas fa-list' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Australia Betting Sites', path: '/sidebar/bestAustralia' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'USA Betting Sites', path: '/sidebar/bestUSA' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'USA Review', icon: 'fas fa-football-ball' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Review', path: '/usaReview/index' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'State content', path: '/usaReview/state' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Australia Review',
          icon: 'fas fa-horse-head',
          path: '/australiaReview/index'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Blog',
          icon: 'fas fa-blog',
          path: '/blog/index'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Free Competitions',
          icon: 'fas fa-comment',
          path: '/freeCompetitions/index'
        }}}),_c('sidebar-item',{attrs:{"link":{ name: 'EV Punting', icon: 'fas fa-running' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'List', path: '/evPunting/index' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Catagory', path: '/evPunting/cat' }}})],1),_c('sidebar-item',{attrs:{"link":{ name: 'Prediction', icon: 'fas fa-comment-dollar' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'List', path: '/prediction/index' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Sports Catagory', path: '/prediction/cat' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'fas fa-users',
          path: '/users/index'
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }