<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">Upload Blog</h4>
        </template>
        <div>
          <form method="#" action="#" @submit.prevent="addNews" onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">Title</label>
              <div class="col-sm-4">
                <fg-input v-model="title" type="text" placeholder="Title of the competition">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Content</label>
              <div class="col-sm-8">
                <vue-editor v-model="content"></vue-editor>
                <br>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Link</label>
              <div class="col-sm-4">
                <fg-input v-model="link" type="text" placeholder="Link for the Competition">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Post
              </n-button>
              </div>
            </div>
            
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';
import { VueEditor, Quill } from "vue2-editor";
import { Upload } from 'element-ui'; 

export default {
  components: {
    Checkbox,
    Radio,
    VueEditor,
    Quill,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload
  },
  data() {

    return {
      title: null,
      content: null,
      link: null,
    };
  },
      methods: {

        addNews () {


                // add the data to db
                db.collection('freeCompetions').add({
                    link: this.link,
                    content: this.content,
                    title: this.title
                })
                .then(docRef => {
                    console.log('News added: ', docRef.id)
                    alert("Succefully Added Free Competitions");
                    this.$router.push('/freeCompetitions/index')
                })
                .catch(error => {
                    console.error('Error adding Shows: ', error)
                })




        }
      }
};
</script>
<style>
  .ql-toolbar .ql-formats .ql-image {
    display: none;
  }
</style>