var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"info-text"},[_vm._v(" Let's start with the basic information (with validation) ")]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"picture-container"},[_c('el-upload',{staticClass:"picture",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-change":_vm.handlePreview,"auto-upload":false,"show-file-list":false}},[(_vm.imageUrl)?_c('img',{staticClass:"picture-src",attrs:{"src":_vm.imageUrl}}):_vm._e()]),_c('h6',{staticClass:"description"},[_vm._v("Choose Picture")])],1)]),_c('div',{staticClass:"col-sm-6"},[_c('ValidationProvider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"form-control-lg",attrs:{"type":"text","error":failed ? 'The First name field is required' : null,"hasSuccess":passed,"placeholder":"First Name (required)","addon-left-icon":"now-ui-icons users_circle-08"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"form-control-lg",attrs:{"error":failed ? 'The Last name field is required' : null,"hasSuccess":passed,"placeholder":"Last Name (required)","addon-left-icon":"now-ui-icons text_caps-small"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})]}}])})],1),_c('div',{staticClass:"col-12 col-sm-10 mt-3"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{staticClass:"form-control-lg",attrs:{"error":failed ? 'The Email field is required' : null,"hasSuccess":passed,"name":"email","placeholder":"Email (required)","addon-left-icon":"now-ui-icons text_caps-small"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }