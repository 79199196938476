<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul class="footer-menu">
          <li>
            <router-link>Aight Bet Now</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, Aight Bet Now. Developed by
        <a href="https://www.tefer.io/">Tefer.io</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
