<template>
  <div>
    <card>
      <div slot="header">
      <div class='row'>
      <div class='col-md-10'>
        <h4 class="card-title">Users Lists</h4>
      </div>
      </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData">
            <el-table-column min-width="50" type="index"></el-table-column>           
            <el-table-column min-width="150" prop="Name"
                             label="Name">
            </el-table-column>
            <el-table-column min-width="200" prop="Email"
                             label="Email">
            </el-table-column>
            <el-table-column min-width="120" prop="City"
                             label="City">
            </el-table-column>
            <el-table-column min-width="120" prop="Country"
                             label="Country">
            </el-table-column>
            <el-table-column min-width="150" prop="FavoriteSport"
                             label="FavoriteSport">
            </el-table-column>
            <el-table-column min-width="160" prop="WeeklyBetting"
                             label="WeeklyBetting">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import db from '@/components/firebaseInit'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
      return {
          tableData: []
      }
  },
  created () {
    db.collection('users').orderBy('FirstName').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = {
          'id': doc.id,
          'Name': doc.data().FirstName +" "+ doc.data().LastName,
          'Email': doc.data().Email,
          'WeeklyBetting': doc.data().WeeklyBetting,
          'FavoriteSport': doc.data().FavoriteSport,
          'Country': doc.data().Country,
          'City': doc.data().City
        }
        this.tableData.push(data)
      })
    })
  }
};
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
