export default {
    apiKey: "AIzaSyBOwthRhJugnCQhFZB1zE8KqEULJy_nT08",
    authDomain: "aightbetnow-com.firebaseapp.com",
    databaseURL: "https://aightbetnow-com.firebaseio.com",
    projectId: "aightbetnow-com",
    storageBucket: "aightbetnow-com.appspot.com",
    messagingSenderId: "806934694321",
    appId: "1:806934694321:web:ed91a72ac7c390a7d39590",
    measurementId: "G-H1SJZ1C21V"
  }
  