<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">Australia Review Add</h4>
        </template>
        <div>
          <form method="#" action="#" @submit.prevent="addNews" onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">Button</label>
              <div class="col-sm-4">
                <fg-input v-model="buttonName" type="text" placeholder="Button Name">
                </fg-input>
              </div>
            </div>
             <div class="row">
              <label class="col-sm-2 col-form-label">Button Link</label>
              <div class="col-sm-4">
                <fg-input v-model="buttonLink" type="text" placeholder="Button Link">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Content</label>
              <div class="col-sm-4">
                <fg-input v-model="content" type="text" placeholder="Content">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Point 1</label>
              <div class="col-sm-4">
                <fg-input v-model="point1" type="text" placeholder="Point 1">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Point 2</label>
              <div class="col-sm-4">
                <fg-input v-model="point2" type="text" placeholder="Point 2">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Point 3</label>
              <div class="col-sm-4">
                <fg-input v-model="point3" type="text" placeholder="Point 3">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Main Content</label>
              <div class="col-sm-8">
                <vue-editor v-model="contentDetail"></vue-editor>
                <br>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Image</label>
              <div class="col-sm-4">
                <!-- <fg-input v-model="imageURL" type="text" placeholder="Image URL">
                </fg-input> -->
                <el-upload class="picture"
                     action="https://jsonplaceholder.typicode.com/posts/"
                     :on-change="handlePreview"
                     :auto-upload="false"
                     :show-file-list="false">
                    <img v-if="model.imageUrl" :src="model.imageUrl" class="picture-src">
                </el-upload>
              </div>
            </div>

     
            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Post
              </n-button>
              </div>
            </div>
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import { VueEditor, Quill } from "vue2-editor";

import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';

import { Upload } from 'element-ui'; 

export default {
  components: {
    Checkbox,
    VueEditor,
    Quill,
    Radio,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload
  },
  data() {

    return {
      catagory: null,
      title: null,
      content: null,
      tag: null,
      date: null,
      logo: null,
      buttonName: null,
      buttonLink: null,
      point1: null,
      point2: null,
      point3: null,
      contentDetail: null,
      imageUrl: null,
      date: new Date(),
      time: '01:00',
      uploadValue: null,
      picture: null,
      imageData: null,
      model: {
          imageUrl: 'img/image_placeholder.jpg'
      }
    };
  },
      methods: {

        handlePreview(file) {
        this.model.imageUrl = URL.createObjectURL(file.raw);
        this.imageData = event.target.files[0];
        },
        getError(fieldName) {
        return this.errors.first(fieldName);
        },
        validate() {
        return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model);
            return res;
        });
        },

        addNews () {

   
      this.picture=null;
      let timename = Date.now();
      const storageRef=firebase.storage().ref(`${timename}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        //   console.log(this.picture);


          // Date and Time Functions
          function toTimestamp(strDate){
            var datum = Date.parse(strDate);
            return datum/1000;
          }
          function convert(str) {
            var date = new Date(str),
              mnth = ("0" + (date.getMonth() + 1)).slice(-2),
              day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
          }   

          const time = toTimestamp(convert(this.date) + " " + this.time);
          const a = new Date(time * 1000);
          const year = a.getFullYear();
          const month = a.getMonth() + 1;
          const day = a.getDate();
          const yearMonth = day + "-" + month +"-"+ year;
          
               let idDate = Number(new Date().valueOf());


                // add the data to db
                db.collection('australiaReviewsMain').add({
                    point1: this.point1,
                    point2: this.point2,
                    point3: this.point3,
                    content: this.content,
                    contentDetail: this.contentDetail,
                    button: this.buttonName,
                    buttonLink: this.buttonLink,
                    logo: this.picture,
                    time: idDate
                })
                .then(docRef => {
                    console.log('News added: ', docRef.id)
                    alert("Succefully Added Blog");
                    this.$router.push('/australiaReview/index')
                })
                .catch(error => {
                    console.error('Error adding Shows: ', error)
                })

        });
      }
      );




        }
      }
};
</script>
<style>
  .ql-toolbar .ql-formats .ql-image {
    display: none;
  }
</style>

