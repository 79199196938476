<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">Free Competitions Edit</h4>
        </template>
        <div>
          <form method="#" action="#" @submit.prevent="updateNews" onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">Title</label>
              <div class="col-sm-4">
                <fg-input v-model="title" type="text" placeholder="Title for the Competition">
                </fg-input>
              </div>
            </div>
             <div class="row">
              <label class="col-sm-2 col-form-label">Content</label>
              <div class="col-sm-8">
                <vue-editor v-model="content"></vue-editor>
                <br>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Tag</label>
              <div class="col-sm-4">
                <fg-input v-model="link" type="text" placeholder="Enter the Link Address">
                </fg-input>
              </div>
            </div>
     
            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Edit
              </n-button>
              <n-button class="btn-block" @click.native="deleteNews" type="default" round>
                Delete
              </n-button>
              </div>
            </div>
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';
import { Upload } from 'element-ui'; 
import { VueEditor, Quill } from "vue2-editor";



export default {
  components: {
    Checkbox,
    Radio,
    VueEditor,
    Quill,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload
  },
  data() {

    return {
      comp_id: null,
      catagory: null,
      title: null,
      content: null,
      tag: null,
      date: null,
      imageUrl: null,
      uploadValue: null,
      picture: null,
      imageData: null,
      model: {
          imageUrl: 'img/image_placeholder.jpg'
      }
    };
  },

    beforeRouteEnter(to, from, next) {
    db
      .collection('freeCompetions').where(firebase.firestore.FieldPath.documentId(), '==', to.params.comp_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.id = doc.id;
            vm.link = doc.data().link;  
            vm.content = doc.data().content;
            vm.title = doc.data().title;
          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
    methods: {

    fetchData () {
      db.collection('freeCompetions')
        .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.comp_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.id = doc.id;
            this.link = doc.data().link;  
            this.content = doc.data().content;
            this.title = doc.data().title;
          });
        });
    },
        updateNews () {

         db.collection('freeCompetions').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.comp_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
                    link: this.link,
                    content: this.content,
                    title: this.title

            })
            .then(() => {
              console.log('News have been Updated')
              alert("Succefully Updated Free Competions");
              this.$router.push('/freeCompetitions/index')
            });
          })
        })  
      
      },
      deleteNews() {
        if (confirm('Are you sure?')) {

            // Delete the Show
            db
            .collection('freeCompetions')
            .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.comp_id)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                doc.ref.delete();
                console.log('News Deleted')
                alert("Succefully Deleted Free Competion");                
                this.$router.push('/freeCompetitions/index');
                });
            });
        }
    }
  }




};
</script>
<style>
  .ql-toolbar .ql-formats .ql-image {
    display: none;
  }
</style>