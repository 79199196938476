<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">Australia Review Edit</h4>
        </template>
        <div>
          <form method="#" action="#" @submit.prevent="updateNews" onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">Button</label>
              <div class="col-sm-4">
                <fg-input v-model="buttonName" type="text" placeholder="Button Name">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Button Link</label>
              <div class="col-sm-4">
                <fg-input v-model="buttonLink" type="text" placeholder="Button Link">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">State</label>
              <div class="col-sm-4 col-md-10">
                                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="State"
                  v-model="state"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Content</label>
              <div class="col-sm-4">
                <fg-input v-model="content" type="text" placeholder="Content">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Point 1</label>
              <div class="col-sm-4">
                <fg-input v-model="point1" type="text" placeholder="Point 1">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Point 2</label>
              <div class="col-sm-4">
                <fg-input v-model="point2" type="text" placeholder="Point 2">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Point 3</label>
              <div class="col-sm-4">
                <fg-input v-model="point3" type="text" placeholder="Point 3">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Main Content</label>
              <div class="col-sm-8">
                <vue-editor v-model="contentDetail"></vue-editor>
                <br>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Image</label>
              <div class="col-sm-4">
                <!-- <fg-input v-model="imageURL" type="text" placeholder="Image URL">
                </fg-input> -->
                <el-upload class="picture"
                     action="https://jsonplaceholder.typicode.com/posts/"
                     :on-change="handlePreview"
                     :auto-upload="false"
                     :show-file-list="false">
                    <img v-if="model.imageUrl" :src="model.imageUrl" class="picture-src">
                </el-upload>
              </div>
            </div>

     
            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Edit
              </n-button>
              <n-button class="btn-block" @click.native="deleteNews" type="default" round>
                Delete
              </n-button>
              </div>
            </div>
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import { VueEditor, Quill } from "vue2-editor";
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';
import { Upload } from 'element-ui'; 


export default {
  components: {
    VueEditor,
    Quill,
    Checkbox,
    Radio,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload
  },
  data() {

    return {
      item_id: null,
      selects: {
        countries: [
          { value: "NJ", label: "NJ" },
          { value: "PA", label: "PA" },
          { value: "Colorado", label: "Colorado" },
          { value: "Illinois", label: "Illinois" },
          { value: "Indiana", label: "Indiana" },
          { value: "Iowa", label: "Iowa" },
          { value: "Michigan", label: "Michigan" },
          { value: "Mississippi", label: "Mississippi" },
          { value: "Nevada", label: "Nevada" },
          { value: "NewHampshire", label: "New Hampshire" },
          { value: "NewYork", label: "New York" },
          { value: "Oregon", label: "Oregon" },
          { value: "Tennessee", label: "Tennessee" },
          { value: "Virginia", label: "Virginia" },
          { value: "WestVirginia", label: "WestVirginia" }
        ],
      },
      catagory: null,
      link: null,
      buttonName: null,
      buttonLink: null,
      content: null,
      point1: null,
      point2: null,
      point3: null,
      logo: null,
      state: null,
      date: null,
      imageUrl: null,
      uploadValue: null,
      picture: null,
      imageData: null,
      model: {
          imageUrl: 'img/image_placeholder.jpg'
      }
    };
  },

    beforeRouteEnter(to, from, next) {
    db
      .collection('usaReviewsMain').where(firebase.firestore.FieldPath.documentId(), '==', to.params.item_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.id = doc.id;
            vm.buttonName = doc.data().button;  
            vm.buttonLink = doc.data().buttonLink;  
            vm.state = doc.data().state; 
            vm.content = doc.data().content;
            vm.contentDetail = doc.data().contentDetail;
            vm.point1 = doc.data().point1;
            vm.point2 = doc.data().point2;
            vm.point3 = doc.data().point3;
            vm.picture = doc.data().logo;
            vm.model.imageUrl = doc.data().logo;
          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
    methods: {

        handlePreview(file) {
        this.model.imageUrl = URL.createObjectURL(file.raw);
        this.imageData = event.target.files[0];
        },
        getError(fieldName) {
        return this.errors.first(fieldName);
        },
        validate() {
        return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model);
            return res;
        });
        },

    fetchData () {
      db.collection('usaReviewsMain')
        .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.id = doc.id;
            this.buttonName = doc.data().button;  
            this.buttonLink = doc.data().buttonLink;  
            this.state = doc.data().state;  
            this.content = doc.data().content;
            this.contentDetail = doc.data().contentDetail;
            this.point1 = doc.data().point1;
            this.point2 = doc.data().point2;
            this.point3 = doc.data().point3;
            this.picture = doc.data().logo;
            this.model.imageUrl = doc.data().logo;
          });
        });
    },
        updateNews () {

if(this.imageData == null){

         db.collection('usaReviewsMain').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
                    button: this.buttonName,
                    buttonLink: this.buttonLink,
                    point1: this.point1,
                    point2: this.point2,
                    point3: this.point3,
                    state: this.state,
                    content: this.content,
                    contentDetail: this.contentDetail,
                    logo: this.picture

            })
            .then(() => {
              console.log('News have been Updated')
              alert("Succefully Updated latest Predictions");
              this.$router.push('/usaReview/index')
            });
          })
        })

}else{

      this.picture=null;
      let timename = Date.now();
      const storageRef=firebase.storage().ref(`${timename}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        //   console.log(this.picture);


        db.collection('usaReviewsMain').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
                    button: this.buttonName,
                    buttonLink: this.buttonLink,
                    point1: this.point1,
                    point2: this.point2,
                    state: this.state,
                    point3: this.point3,
                    content: this.content,
                    contentDetail: this.contentDetail,
                    logo: this.picture

            })
            .then(() => {
              console.log('News have been Updated')
              alert("Succefully Updated latest Predictions");
              this.$router.push('/usaReview/index')
            });
          })
        })



        });
      }
      );


}



      
      
      },
      deleteNews() {
        if (confirm('Are you sure?')) {

            // Delete the Show
            db
            .collection('usaReviewsMain')
            .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                doc.ref.delete();
                console.log('News Deleted')
                alert("Succefully Deleted Blog");                
                this.$router.push('/usaReview/index');
                });
            });
        }
    }


  }




};
</script>
<style>
  .ql-toolbar .ql-formats .ql-image {
    display: none;
  }
</style>