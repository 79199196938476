<template>
  <div>
    <card>
      <div slot="header">
      <div class='row'>
      <div class='col-md-10'>
        <h4 class="card-title">USA State Content</h4>
      </div>
      </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="selects.countries">
            <el-table-column min-width="50" type="index"></el-table-column>          
            <el-table-column min-width="150" prop="label"
                             label="State">
            </el-table-column>
            <!-- <el-table-column min-width="150" prop="Date"
                             label="Date">
            </el-table-column> -->
            <el-table-column min-width="150"
                             header-align="right"
                             label="Actions">
              <div slot-scope="{row}" class="text-right table-actions">
                <router-link v-bind:to="{name: 'USA State Edit', params: { item_id: row.value }}">
                  <el-tooltip content="Settings"
                              :open-delay="300"
                              placement="top">
                    <n-button type="warning" size="sm" icon>
                      <i class="now-ui-icons ui-2_settings-90"></i>
                    </n-button>
                  </el-tooltip>
                </router-link>

              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';
import db from '@/components/firebaseInit'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
      return {
      selects: {
        countries: [
          { value: "NJ", label: "NJ" },
          { value: "PA", label: "PA" },
          { value: "Colorado", label: "Colorado" },
          { value: "Illinois", label: "Illinois" },
          { value: "Indiana", label: "Indiana" },
          { value: "Iowa", label: "Iowa" },
          { value: "Michigan", label: "Michigan" },
          { value: "Mississippi", label: "Mississippi" },
          { value: "Nevada", label: "Nevada" },
          { value: "NewHampshire", label: "New Hampshire" },
          { value: "NewYork", label: "New York" },
          { value: "Oregon", label: "Oregon" },
          { value: "Tennessee", label: "Tennessee" },
          { value: "Virginia", label: "Virginia" },
          { value: "WestVirginia", label: "WestVirginia" }
        ],
      },
      }
  },
};
</script>
<style lang="scss">
.checkbox-cell {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
