<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">Homepage Banner Edit</h4>
        </template>
        <div>
          <form method="#" action="#" @submit.prevent="updateNews" onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal">
            <div class="row">
              <label class="col-sm-2 col-form-label">Name</label>
              <div class="col-sm-4">
                {{this.name}}
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Link</label>
              <div class="col-sm-4">
                <fg-input v-model="link" type="text" placeholder="Link of Banner">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-2 col-form-label">Image</label>
              <div class="col-sm-4">
                <!-- <fg-input v-model="imageURL" type="text" placeholder="Image URL">
                </fg-input> -->
                <el-upload class="picture"
                     action="https://jsonplaceholder.typicode.com/posts/"
                     :on-change="handlePreview"
                     :auto-upload="false"
                     :show-file-list="false">
                    <img v-if="model.imageUrl" :src="model.imageUrl" class="picture-src">
                </el-upload>
              </div>
            </div>

     
            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Edit
              </n-button>
              <!-- <n-button class="btn-block" @click.native="deleteNews" type="default" round>
                Delete
              </n-button> -->
              </div>
            </div>
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';
import { Upload } from 'element-ui'; 


export default {
  components: {
    Checkbox,
    Radio,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload
  },
  data() {

    return {
      item_id: null,
      catagory: null,
      name: null,
      content: null,
      link: null,
      date: null,
      imageUrl: null,
      uploadValue: null,
      picture: null,
      imageData: null,
      model: {
          imageUrl: 'img/image_placeholder.jpg'
      }
    };
  },

    beforeRouteEnter(to, from, next) {
    db
      .collection('homepageBanners').where(firebase.firestore.FieldPath.documentId(), '==', to.params.item_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.id = doc.id;
            vm.name = doc.data().name;  
            vm.link = doc.data().imgLink;
            vm.picture = doc.data().link;
            vm.model.imageUrl = doc.data().link;
          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
    methods: {

        handlePreview(file) {
        this.model.imageUrl = URL.createObjectURL(file.raw);
        this.imageData = event.target.files[0];
        },
        getError(fieldName) {
        return this.errors.first(fieldName);
        },
        validate() {
        return this.$validator.validateAll().then(res => {
            this.$emit('on-validated', res, this.model);
            return res;
        });
        },

    fetchData () {
      db.collection('homepageBanners')
        .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.id = doc.id;
            this.name = doc.data().name;  
            this.link = doc.data().imgLink;
            this.picture = doc.data().link;
            this.model.imageUrl = doc.data().link;
          });
        });
    },
        updateNews () {

if(this.imageData == null){

         db.collection('homepageBanners').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
                    imgLink: this.link,
                    link: this.picture

            })
            .then(() => {
              console.log('News have been Updated')
              alert("Succefully Updated Blog");
              this.$router.push('/homepage/banners')
            });
          })
        })

}else{

      this.picture=null;
      let timename = Date.now();
      const storageRef=firebase.storage().ref(`${timename}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        //   console.log(this.picture);


        db.collection('homepageBanners').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.item_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
                    imgLink: this.link,
                    link: this.picture

            })
            .then(() => {
              console.log('News have been Updated')
              alert("Succefully Updated Blog");
              this.$router.push('/homepage/banners')
            });
          })
        })



        });
      }
      );


}



      
      
      },

  }




};
</script>
<style>
</style>
