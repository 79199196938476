<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="col-md-4 ml-auto mr-auto">
        <form @submit.prevent="login">
          <card class="card-login card-plain">
            <div slot="header">
              <div class="logo-container">
                <img src="img/homepage/logo-2.png" alt="" />
              </div>
            </div>

            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="email"
                  :error="failed ? 'The Email field is required' : null"
                  :hasSuccess="passed"
                  class="no-border form-control-lg"
                  placeholder="Email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                  v-model="email"
                >
                </fg-input>
              </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required|min:5"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="password"
                  :error="failed ? 'The Password field is required' : null"
                  :hasSuccess="passed"
                  class="no-border form-control-lg"
                  placeholder="Password"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
                  v-model="password"
                >
                </fg-input>
              </ValidationProvider>
            </div>

            <div slot="footer">
              <n-button native-type="submit" type="primary" round block>
                Login
              </n-button>

            </div>
          </card>
        </form>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

import db from '@/components/firebaseInit'
import Swal from "sweetalert2";
import firebase from 'firebase';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
     login () {
      // let isValidForm = await this.$validator.validate();
      // if (isValidForm) {
      //   // TIP use this.model to send it to api and perform login call
      // }
      // let stats = this.$store.state.models;
      // stats.logedIn == true;
                  

                  firebase
                    .auth()
                    .signInWithEmailAndPassword(this.email, this.password)
                    .then(
                      user => {

                        // console.log(user);
                        // alert(`You are logged in as ${this.email}`);
                        this.$router.push('/homepage/banners');
                        stats.logedIn == true;

                      },
                      err => {
                        alert(err.message);
                      }
                    );
                    e.preventDefault();



      
    },
    submit() {
      alert("Form has been submitted!");
    }
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
