<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <h4 class="card-title">USA State Edit</h4>
        </template>
        <div>
          <form method="#" action="#" @submit.prevent="updateNews" onSubmit="document.getElementById('submit').disabled=true;"  class="form-horizontal">
           <div class="row">
              <label class="col-sm-2 col-form-label">Front Content</label>
              <div class="col-sm-4">
                <fg-input v-model="contentFront" type="text" placeholder="Front Content">
                </fg-input>
              </div>
            </div>
            <br><br>
           <div class="row">
              <label class="col-sm-2 col-form-label">Main Content</label>
              <div class="col-sm-8">
                <vue-editor v-model="content"></vue-editor>
                <br>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-2 col-form-label"></label>
              <div class="col-sm-4">
                <n-button class="btn-block" native-type="submit" type="primary" id='submit' round>
                Edit
              </n-button>
              </div>
            </div>
          </form>
        </div>
      </card>  <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio } from 'src/components/index';
import { VueEditor, Quill } from "vue2-editor";

import {
  TimeSelect,
  DatePicker,
  Tag,
  Input,
  Button,
  Select,
  Option
} from 'element-ui';

import db from '@/components/firebaseInit'
import firebase from 'firebase';
import { Upload } from 'element-ui'; 


export default {
  components: {
    VueEditor,
    Quill,
    Checkbox,
    Radio,
    [Option.name]: Option,
    [Select.name]: Select,
    [Upload.name]: Upload
  },
  data() {

    return {
      item_id: null,
      catagory: null,
      name: null,
      content: null,
      link: null,
      date: null,
      contentFront: null,
      imageUrl: null,
      uploadValue: null,
      picture: null,
      imageData: null,
      model: {
          imageUrl: 'img/image_placeholder.jpg'
      }
    };
  },

    beforeRouteEnter(to, from, next) {
    db
      .collection('usaStates').where('name', '==', to.params.item_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.id = doc.id;
            vm.content = doc.data().content;  
            vm.contentFront = doc.data().contentFront;
          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
    methods: {
    fetchData () {
      db.collection('usaStates')
        .where('name', '==', this.$route.params.item_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.id = doc.id;
            this.content = doc.data().content;  
            this.contentFront = doc.data().contentFront;
          });
        });
    },

        updateNews () {

         db.collection('usaStates').where('name', '==', this.$route.params.item_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
                    content: this.content,
                    contentFront: this.contentFront

            })
            .then(() => {
              console.log('News have been Updated')
              alert("Succefully Updated State Content");
              this.$router.push('/usaReview/state')
            });
          })
        })
      
      },

  }




};
</script>
<style>
  .ql-toolbar .ql-formats .ql-image {
    display: none;
  }
</style>
