var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"form-horizontal",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Range Validation ")]),_c('div',[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Min Length")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"minLength","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Min Length field is required' : null,"hasSuccess":passed,"name":"minLength"},model:{value:(_vm.minLength),callback:function ($$v) {_vm.minLength=$$v},expression:"minLength"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("min=\"5\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Max Length")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"maxLength","rules":"required|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Max Length field is required' : null,"hasSuccess":passed,"name":"maxLength"},model:{value:(_vm.maxLength),callback:function ($$v) {_vm.maxLength=$$v},expression:"maxLength"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("max=\"5\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Range")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"range","rules":"required|min:6|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Range field is required' : null,"hasSuccess":passed,"name":"range"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("min_value=\"6\", max_value=\"10\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Min Value")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"minValue","rules":"required|min_value:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Min Value field is required' : null,"hasSuccess":passed,"name":"minValue"},model:{value:(_vm.minValue),callback:function ($$v) {_vm.minValue=$$v},expression:"minValue"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("min_value=\"6\"")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Max Value")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"maxValue","rules":"required|max_value:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Max Value field is required' : null,"hasSuccess":passed,"name":"maxValue"},model:{value:(_vm.maxValue),callback:function ($$v) {_vm.maxValue=$$v},expression:"maxValue"}})]}}],null,true)})],1),_c('label',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("max_value=\"6\"")])])])]),_c('div',{staticClass:"text-center"},[_c('n-button',{attrs:{"native-type":"submit","type":"primary"}},[_vm._v("Validate inputs")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }