import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Dashboard/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Page Headers
import DefaultHeader from "src/pages/Dashboard/DefaultHeader";

  const HomePageAR = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/australiaReview.vue"
  );
  const HomePageARE = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/australiaReviewEdit.vue"
  );
  const HomePageBanner = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/banners.vue"
  );
  const HomePageBannerEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/bannersEdit.vue"
  );
  const HomePageLP = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/latestPredictions.vue"
  );
  const HomePageLPE = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/latestPredictionsEdit.vue"
  );
  const HomePageUR = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/usaReview.vue"
  );
  const HomePageURE = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Homepage/usaReviewEdit.vue"
  );


  const SidebarAU = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Sidebar/bestAustralia.vue"
  );
  const SidebarAUAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Sidebar/bestAustraliaAdd.vue"
  );
  const SidebarAUE = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Sidebar/bestAustraliaEdit.vue"
  );

  const SidebarUS = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Sidebar/bestUSA.vue"
  );
  const SidebarUSAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Sidebar/bestUSAAdd.vue"
  );
  const SidebarUSE = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Sidebar/bestUSAEdit.vue"
  );


  const USAReview = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/USAReview/index.vue"
  );
  const USAReviewAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/USAReview/add.vue"
  );
  const USAReviewEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/USAReview/edit.vue"
  );
  const USAState = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/USAReview/stateBlurb.vue"
  );
  const USAStateEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/USAReview/stateBlurbEdit.vue"
  );

  const AustraliaReview = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/AustraliaReview/index.vue"
  );
  const AustraliaReviewAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/AustraliaReview/add.vue"
  );
  const AustraliaReviewEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/AustraliaReview/edit.vue"
  );


  const Blog = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Blogs/index.vue"
  );
  const BlogAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Blogs/add.vue"
  );
  const BlogEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Blogs/edit.vue"
  );

  const Prediction = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Prediction/index.vue"
  );
  const PredictionAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Prediction/add.vue"
  );
  const PredictionEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Prediction/edit.vue"
  );

  const PredictionCat = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Prediction/catList.vue"
  );
  const PredictionCatEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Prediction/catEdit.vue"
  );

  const FreeCompetitions = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/FreeCompetitions/index.vue"
  );
  const FreeCompetitionsAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/FreeCompetitions/add.vue"
  );
  const FreeCompetitionsEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/FreeCompetitions/edit.vue"
  );

  const EVPunting = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/EVPunting/index.vue"
  );
  const EVPuntingAdd = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/EVPunting/add.vue"
  );
  const EVPuntingEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/EVPunting/edit.vue"
  );
  const EVPuntingCat = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/EVPunting/evCat.vue"
  );
  const EVPuntingCatEdit = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/EVPunting/evCatEdit.vue"
  );

  const SettingPassword = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Settings/changePassword.vue"
  );

  const Users = () =>
  import(
    /* webpackChunkName: "components" */ "src/pages/Dashboard/Pages/Users/index.vue"
  );


// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
  );
import Widgets from "src/pages/Dashboard/Widgets.vue";

// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// Maps pages
const GoogleMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/GoogleMaps.vue"
  );
const FullScreenMap = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/FullScreenMap.vue"
  );
const VectorMaps = () =>
  import(
    /* webpackChunkName: "maps" */ "src/pages/Dashboard/Maps/VectorMaps.vue"
  );

// Pages
const User = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/UserProfile.vue"
  );
const Pricing = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/Pricing.vue"
  );
const TimeLine = () =>
  import(
    /* webpackChunkName: "pages" */ "src/pages/Dashboard/Pages/TimeLinePage.vue"
  );
const Login = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Login.vue");
const Register = () =>
  import(
    /* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Register.vue"
  );
const Lock = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Dashboard/Pages/Lock.vue");


  let usaReviewMenu = {
    path: "/usaReview",
    component: DashboardLayout,
    name: "USA Review",
    redirect: "/usaReview/index",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "index",
        name: "USA Review List",
        components: { default: USAReview, header: DefaultHeader }
      },
      {
        path: "add",
        name: "USA Review Add",
        components: { default: USAReviewAdd, header: DefaultHeader }
      },
      {
        path: "edit/:item_id",
        name: "USA Review Edit",
        components: { default: USAReviewEdit, header: DefaultHeader }
      },
      {
        path: "state",
        name: "USA State List",
        components: { default: USAState, header: DefaultHeader }
      },
      {
        path: "stateEdit/:item_id",
        name: "USA State Edit",
        components: { default: USAStateEdit, header: DefaultHeader }
      }
    ]
  };

  let australiaReviewMenu = {
    path: "/australiaReview",
    component: DashboardLayout,
    name: "Australia Review",
    redirect: "/australiaReview/index",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "index",
        name: "Australia Review List",
        components: { default: AustraliaReview, header: DefaultHeader }
      },
      {
        path: "add",
        name: "Australia Review Add",
        components: { default: AustraliaReviewAdd, header: DefaultHeader }
      },
      {
        path: "edit/:item_id",
        name: "Australia Review Edit",
        components: { default: AustraliaReviewEdit, header: DefaultHeader }
      }
    ]
  };

  let sidebarMenu = {
    path: "/sidebar",
    component: DashboardLayout,
    name: "Sidebar",
    redirect: "/sidebar/bestAustralia",
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "bestAustralia",
        name: "Best Australia Betting Sites",
        components: { default: SidebarAU, header: DefaultHeader }
      }, 
      {
        path: "bestAustralia/add",
        name: "Best Australia Betting Sites Add",
        components: { default: SidebarAUAdd, header: DefaultHeader }
      },
      {
        path: "bestAustralia/edit/:item_id",
        name: "Best Australia Betting Sites Edit",
        components: { default: SidebarAUE, header: DefaultHeader }
      },
      {
        path: "bestUSA",
        name: "Best USA Betting Sites",
        components: { default: SidebarUS, header: DefaultHeader }
      },
      {
        path: "bestUSA/Add",
        name: "Best USA Betting Sites Add",
        components: { default: SidebarUSAdd, header: DefaultHeader }
      },
      {
        path: "bestUSA/edit/:item_id",
        name: "Best USA Betting Sites Edit",
        components: { default: SidebarUSE, header: DefaultHeader }
      },
    ]
  };

let blogMenu = {
  path: "/blog",
  component: DashboardLayout,
  name: "Blog",
  redirect: "/blog/index",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "index",
      name: "Blog List",
      components: { default: Blog, header: DefaultHeader }
    },
    {
      path: "add",
      name: "Blog Add",
      components: { default: BlogAdd, header: DefaultHeader }
    },
    {
      path: "edit/:blog_id",
      name: "Blog Edit",
      components: { default: BlogEdit, header: DefaultHeader }
    }
  ]
};

let predictionMenu = {
  path: "/prediction",
  component: DashboardLayout,
  name: "Prediction",
  redirect: "/prediction/index",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "index",
      name: "Prediction List",
      components: { default: Prediction, header: DefaultHeader }
    },
    {
      path: "add",
      name: "Prediction Add",
      components: { default: PredictionAdd, header: DefaultHeader }
    },
    {
      path: "edit/:item_id",
      name: "Prediction Edit",
      components: { default: PredictionEdit, header: DefaultHeader }
    },
    {
      path: "cat",
      name: "Prediction Catagory List",
      components: { default: PredictionCat, header: DefaultHeader }
    },
    {
      path: "catEdit/:item_id",
      name: "Prediction Catagory Edit",
      components: { default: PredictionCatEdit, header: DefaultHeader }
    }
  ]
};

let homepageMenu = {
  path: "/homepage",
  component: DashboardLayout,
  name: "homepage",
  redirect: "/homepage/banners",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "banners",
      name: "Banners",
      components: { default: HomePageBanner, header: DefaultHeader }
    },
    {
      path: "banners/edit/:item_id",
      name: "Banners Edit",
      components: { default: HomePageBannerEdit, header: DefaultHeader }
    },
    {
      path: "australiaReview",
      name: "Australia Review",
      components: { default: HomePageAR, header: DefaultHeader }
    },
    {
      path: "australiaReview/edit/:item_id",
      name: "Australia Review Edit Home",
      components: { default: HomePageARE, header: DefaultHeader }
    },
    {
      path: "usaReview",
      name: "USA Review",
      components: { default: HomePageUR, header: DefaultHeader }
    },
    {
      path: "usaReview/edit/:item_id",
      name: "USA Review Edit Home",
      components: { default: HomePageURE, header: DefaultHeader }
    },
    {
      path: "latestPredictions",
      name: "Latest Predictions",
      components: { default: HomePageLP, header: DefaultHeader }
    },
    {
      path: "latestPredictions/edit/:item_id",
      name: "Latest Predictions Edit",
      components: { default: HomePageLPE, header: DefaultHeader }
    },
  ]
};

let eVPuntingMenu = {
  path: "/evPunting",
  component: DashboardLayout,
  name: "EVPunting",
  redirect: "/evPunting/index",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "index",
      name: "EV Punting List",
      components: { default: EVPunting, header: DefaultHeader }
    },
    {
      path: "add",
      name: "EV Punting Add",
      components: { default: EVPuntingAdd, header: DefaultHeader }
    },
    {
      path: "edit/:item_id",
      name: "EV Punting Edit",
      components: { default: EVPuntingEdit, header: DefaultHeader }
    },
    {
      path: "cat",
      name: "EV Punting Catagory",
      components: { default: EVPuntingCat, header: DefaultHeader }
    },
    {
      path: "catEdit/:item_id",
      name: "EV Punting Catagory Edit",
      components: { default: EVPuntingCatEdit, header: DefaultHeader }
    }
  ]
};

let freeCompetitionsMenu = {
  path: "/freeCompetitions",
  component: DashboardLayout,
  name: "EVPunting",
  redirect: "/freeCompetitions/index",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "index",
      name: "Free Competitions List",
      components: { default: FreeCompetitions, header: DefaultHeader }
    },
    {
      path: "add",
      name: "Free Competitions Add",
      components: { default: FreeCompetitionsAdd, header: DefaultHeader }
    },
    {
      path: "edit/:comp_id",
      name: "Free-Competitions-Edit",
      components: { default: FreeCompetitionsEdit, header: DefaultHeader }
    }
  ]
};

let settingPasswordMenu = {
  path: "/settings",
  component: DashboardLayout,
  name: "Settings",
  redirect: "/settings/changePassword",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "changePassword",
      name: 'Change Password',
      components: { default: SettingPassword, header: DefaultHeader }
    }
  ]
};

let usersMenu = {
  path: "/users",
  component: DashboardLayout,
  name: "Users",
  redirect: "/users/index",
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: "index",
      name: "Users List",
      components: { default: Users, header: DefaultHeader }
    }
  ]
};


let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/homepage",
    name: "homepage",
  },
  australiaReviewMenu,
  usaReviewMenu,
  blogMenu,
  predictionMenu,
  eVPuntingMenu,
  freeCompetitionsMenu,
  settingPasswordMenu,
  usersMenu,
  homepageMenu,
  sidebarMenu,
  authPages,
  { path: "*", component: NotFound }
];

export default routes;
